import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import React from "react";
import Login from "./Login";
import Register from "./Register";

const headerRender = {
  login: {
    title: "Đăng Nhập",
    subTitle: "Chào Mừng Đã Quay Trở Lại Với Clone Business",
  },
  register: {
    title: "Đăng Ký",
    subTitle: "Đăng Ký Tài Khoản Để Sử Dụng Đầy Đủ Các Dịch Vụ",
  },
  "forgot-password": {
    title: "Quên Mật Khẩu",
    subTitle: "Nhập địa chỉ email để thực hiện khôi phục mật khẩu",
  },
};

type AuthModalProps = {
  open: boolean;
  onClose: () => void;
};

const AuthModal = ({ open, onClose }: AuthModalProps) => {
  const [view, setView] = React.useState<
    "login" | "register" | "forgot-password"
  >("login");

  const handleClose = () => {
    setView("login");
    onClose();
  };

  return (
    <Dialog modal open={open} onOpenChange={handleClose}>
      <DialogContent
        className="md:w-[480px] w-[96vw] max-h-[96vh] overflow-auto"
        title={headerRender[view].title}
      >
        <div className="mx-3 p-4 mb-3 bg-white rounded-3xl mt-4 max-h-[75vh] overflow-auto">
          {view === "login" && (
            <Login
              onClose={onClose}
              onForgotPassword={() => {
                setView("forgot-password");
              }}
              onRegister={() => {
                setView("register");
              }}
            />
          )}

          {view === "register" && (
            <Register
              onLogin={() => {
                setView("login");
              }}
            />
          )}

          {view === "forgot-password" && (
            <div>
              <label className="block font-medium text-sm text-neutural-medium mb-1">
                Địa chỉ email
              </label>
              <Input
                placeholder="Nhập địa chỉ email"
                className="mb-4 bg-gray-50 px-4 py-3 text-neutural-strong border-transparent rounded-lg focus:border-gray-100 hover:border-gray-100"
              />
              <Button variant="primary" className="mt-3 w-full">
                Khôi Phục Mật Khẩu
              </Button>
              <div className="mt-4 text-center">
                <span
                  className="text-primary-content font-semibold cursor-pointer"
                  onClick={() => {
                    setView("login");
                  }}
                >
                  {" "}
                  Trở về đăng nhập
                </span>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AuthModal;
