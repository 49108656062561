import { VoucherGreen } from "@/assets/images";
import { toastError, toastSuccess } from "@/components/ToastComponent";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { REGISTER } from "@/lib/graphql";
import { useMutation } from "@apollo/client";
import { Spinner } from "@phosphor-icons/react";
import { Label } from "@radix-ui/react-label";
import Image from "next/image";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RegisterParams, RegisterResponse } from "../../types/auth";

type Props = {
  onLogin: () => void;
};

const Register = ({ onLogin }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<RegisterParams>();
  const [pwVisible, setPwVisible] = useState(false);

  const [doRegister, { loading }] = useMutation<
    { response: { data: RegisterResponse } },
    {
      params: {
        username: string;
        password: string;
        firstName?: string;
        lastName?: string;
        email?: string;
      };
    }
  >(REGISTER, {
    onCompleted: () => {
      toastSuccess("Đăng ký thành công! Vui lòng đăng nhập để tiếp tục.");
      onLogin();
    },
    onError: () => {
      toastError("Đăng ký thất bại!");
    },
  });

  const onSubmit = async (data: { username: string; password: string }) => {
    const randomUsername =
      "CB" + Math.random().toString(36).substring(7).toUpperCase();
    await doRegister({
      variables: {
        params: {
          email: data.username,
          username: randomUsername,
          password: data.password,
          firstName: "Clone",
          lastName: "Business User",
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-primary-light rounded-3xl p-4 flex gap-2 items-center justify-between">
        <Image
          src={VoucherGreen}
          alt="voucher"
          className="object-fit h-12 w-12 rounded-lg"
        />
        <div>
          <div className="text-neutural-strong text-sm">
            Thanh toán bằng số dự tài khoản để được{" "}
            <span className="text-neutural-strong font-semibold">giảm 1%</span>{" "}
            Tổng số tiền phải thanh toán
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Controller
          control={control}
          name="username"
          rules={{ required: "Tên đăng nhập không được để trống" }}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <Label htmlFor="username">Địa Chỉ Email</Label>
                <Input
                  id="username"
                  onChange={onChange}
                  value={value}
                  type="text"
                  placeholder="Địa Chỉ Email"
                />
              </>
            );
          }}
        />

        <Controller
          control={control}
          name="password"
          rules={{ required: "Mật khẩu không được để trống" }}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <Label htmlFor="password">Mật Khẩu</Label>
                <Input
                  id="password"
                  onChange={onChange}
                  value={value}
                  type="password"
                  placeholder="Nhập mật khẩu"
                />
              </>
            );
          }}
        />

        <Controller
          control={control}
          name="confirmPassword"
          rules={{ required: "Xác nhận mật khẩu không được để trống" }}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <Label htmlFor="confirmPassword">Xác Nhận Mật Khẩu</Label>
                <Input
                  id="confirmPassword"
                  onChange={onChange}
                  value={value}
                  type="password"
                  placeholder="Nhập lại mật khẩu"
                />
              </>
            );
          }}
        />

        <div className="text-neutural-strong font-normal flex md:gap-2 gap-3 mt-1">
          <Controller
            control={control}
            name="acceptTerms"
            rules={{ required: "Xác nhận mật khẩu không được để trống" }}
            render={({ field: { onChange, value } }) => {
              return (
                <input type="checkbox" checked={value} onChange={onChange} />
              );
            }}
          />
          <div>
            Đồng ý với{" "}
            <span className="underline cursor-pointer">
              điều khoản & chính sách
            </span>{" "}
            của Clone Business
          </div>
        </div>
        <Button
          disabled={loading}
          type="submit"
          className="mt-4 w-full conv__signup"
          variant="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {loading ? (
            <Spinner
              className="animate-spin text-neutural-strong text-center"
              size={24}
            />
          ) : (
            "Tạo Tài Khoản"
          )}
        </Button>
      </div>

      <div className="mt-4 text-center">
        <span className="font-medium">Bạn đã có tài khoản?</span>
        <span
          className="text-primary-content font-semibold cursor-pointer conv__signin_or_signup"
          onClick={() => onLogin()}
        >
          {" "}
          Đăng Nhập Ngay
        </span>
      </div>
    </form>
  );
};

export default Register;
